import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumFont,
  mediumRegularFont,
  smallMediumFont,
  smallSemiBoldFont,
} from "../../../../../../../../../styles/classes/gloabl-classes";
import {
  lightShadowSecondary,
  transition,
} from "../../../../../../../../../styles/global-style";

export const WorkoutsContainerDayWorkoutList = styled.ul`
  margin: 0px;
  height: 250px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const WorkoutsContainerDayWorkoutFooter = styled.div`
  ${flexCenterCenter}
  ${lightShadowSecondary}
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px 0px;
`;

export const WorkoutsContainerDayExerciseListItemListItem = styled.li`
  user-select: none;
  ${mediumRegularFont}
  ${flexCenter}
  width: 100%;
  padding: 10px 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const WorkoutsContainerDayExerciseListItemNumberContainer = styled.div`
  ${flexCenter}
  margin-right: 8px;

  p {
    margin: 0px;
    ${mediumFont}
  }

  span {
    font-size: 18px;
    margin-left: 4px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const WorkoutsContainerDayExerciseListItemLGImageContainer = styled.img`
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  position: absolute;
  z-index: 100;
  top: -10px;
  left: 10px;
  opacity: 0;
  height: 0px !important;
  width: 0px !important;
  overflow: hidden;
  transition: ${transition};
`;

export const WorkoutsContainerDayExerciseListItemImageContainer = styled.div<{
  $width: string;
  $height: string;
}>`
  max-width: ${({ $width }) => $width};
  max-height: ${({ $height }) => $height};
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  ${flexCenterCenter}
  margin-right: 8px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  :hover ${WorkoutsContainerDayExerciseListItemLGImageContainer} {
    opacity: 1;
    width: 100px !important;
    height: 100px !important;
    overflow: visible;
  }
`;

export const WorkoutsContainerDayExerciseListItemTextContainer = styled.div`
  max-width: 155px;

  p {
    margin: 0px;
    ${mediumRegularFont}
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    ${smallMediumFont}
    position: relative;
  }
`;

export const WorkoutsContainerDayExerciseListItemInfoContainer = styled.div`
  ${flexCenter}
  margin-left: auto;

  .exercise-list-item-icon {
    color: ${({ theme }) => theme.colors.primaryColor};
    margin-left: 24px;
  }
`;

export const WorkoutsContainerDayExerciseListItemInfoSubContainer = styled.div`
  display: grid;
`;

export const WorkoutsContainerDayExerciseListItemInfoItem = styled.div`
    ${flexCenter}
    gap: 0px 4px;
  
    p {
      margin: 0px;
      ${smallSemiBoldFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  
    span {
      font-size: 15px;
      color: ${({ theme }) => theme.colors.textColor};
    }
  `;
