import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  regularSemiBoldFont,
} from "../../../styles/classes/gloabl-classes";
import { Button } from "../../../styles/classes/reusable-classes";

export const WorkoutsTableRowsThumbnailContainer = styled.div`
  ${flexCenter}

  img {
    width: 35px;
    height: 35px;
  }
`;

export const WorkoutsScreenHeader = styled.header`
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterSpaceBetween}
  gap: 0px 24px;
  background: ${({ theme }) => theme.colors.containerColor};
  height: 85px;

  p {
    margin: 0px;
    ${regularSemiBoldFont}
  }

  @media screen and (max-width: 800px) {
    padding: 16px;

    ${Button} {
      padding: 8px 16px;
    }
  }
`;

export const WorkoutsFormContainer = styled.div`
  height: calc(100vh - 85px);
  padding: 12px 8px;
  background: ${({ theme }) => theme.colors.containerColor};
`;

export const WorkoutsFormControllersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const WorkoutsFormFormSubContainer = styled.div`
  width: 50%;
  display: grid;
  gap: 12px 0px;
`;

export const WorkoutsSubTableContainer = styled.div`
  width: 90%;
  margin: 24px auto 0px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
`;
