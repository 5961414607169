import { useEffect, useState } from "react";
import { Event as StreamChatEvent } from "stream-chat";
import { Link, useLocation } from "react-router-dom";
import { useModalHelpers } from "../../../components/modal/use-modal-helpers";
import { useAuth, useUser } from "../../../context/auth-context/auth-context";
import { LogoutModal } from "../logout-modal/logout-modal";
import {
  SidebarContainer,
  SidebarHeaderContainer,
  SidebarHeaderLogo,
  SidebarLogoTitle,
  SidebarLinksList,
  SidebarLinksListItem,
  SidebarBackDrop,
  CloseIcon,
  UserInfoContainer,
  UserInfoImage,
  UserInfoName,
  MobileLogoutContainer,
  ChatContNumber,
} from "./styled-sidebar";
import {
  FEATURE_FLAG_CHAT,
  FEATURE_FLAG_CONTENT_MODULES,
  FEATURE_FLAG_ORGANIZATIONS_AND_ROLES,
  FEATURE_FLAG_HOME,
  FEATURE_FLAG_EXERCISES,
} from "../../../constants/config";
import { useChatContext } from "stream-chat-react";
import { SidebarUserInfo } from "./sidebar-user-info/sidebar-user-info";

type SidebarProps = {
  showResponsiveSideber: boolean;
  handleHideResponsiveSideber: () => void;
};

export const Sidebar = (props: SidebarProps) => {
  const { showResponsiveSideber, handleHideResponsiveSideber } = props;
  const user = useUser();

  const { closeModal, isModalOpen, openModal } = useModalHelpers();
  const { clearAndUnpersistUser } = useAuth();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(
    location.pathname.includes("/content")
      ? 5
      : location.pathname.includes("/food-logging")
      ? 1
      : location.pathname.includes("/chat")
      ? 2
      : location.pathname.includes("/organizations")
      ? 4
      : location.pathname.includes("/home/")
      ? 6
      : location.pathname.includes("/workouts")
      ? 7
      : 0
  );

  useEffect(() => {
    if (location.pathname.includes("/food-logging")) {
      setActiveLink(1);
    } else if (location.pathname.includes("/chat")) {
      setActiveLink(2);
    } else if (location.pathname.includes("/organizations")) {
      setActiveLink(4);
    } else if (location.pathname.includes("/content")) {
      setActiveLink(5);
    } else if (location.pathname.includes("/home")) {
      setActiveLink(6);
    } else if (location.pathname.includes("/workouts")) {
      setActiveLink(7);
    } else {
      setActiveLink(0);
    }
  }, [location.pathname]);

  const { client } = useChatContext();

  const [unreadCountState, setUnreadCountState] = useState(0);

  useEffect(() => {
    const getCount = async (event: StreamChatEvent) => {
      const unreadCount = event.total_unread_count;
      if (unreadCount !== undefined) {
        setUnreadCountState(unreadCount);
      } else {
        setUnreadCountState(0);
      }
    };

    const onGetCount = client?.on("message.new", getCount);

    return () => {
      onGetCount?.unsubscribe();
    };
  }, [client]);

  return (
    <>
      <SidebarBackDrop
        onClick={handleHideResponsiveSideber}
        showResponsiveSideber={showResponsiveSideber}
      />
      <SidebarContainer showResponsiveSideber={showResponsiveSideber}>
        <CloseIcon onClick={handleHideResponsiveSideber}>
          <span className="material-symbols-outlined">close</span>
        </CloseIcon>
        {/* Mobile View */}
        <SidebarHeaderContainer>
          <SidebarHeaderLogo>
            <img src="/img/logo-2.svg" alt="Otida Logo" />
          </SidebarHeaderLogo>
          <SidebarLogoTitle>otida</SidebarLogoTitle>
        </SidebarHeaderContainer>
        {/* Mobile View */}
        <UserInfoContainer>
          <UserInfoImage>
            <img
              src={
                user.imageUrl === undefined ? "/img/logo-2.svg" : user.imageUrl
              }
              alt="User Profile"
              referrerPolicy="no-referrer"
            />
          </UserInfoImage>
          <div>
            <UserInfoName>{user.name}</UserInfoName>
          </div>
        </UserInfoContainer>
        {/* Mobile View */}
        <SidebarLinksList>
          {buildLinks().map((link, index) => (
            <SidebarLinksListItem
              activeLink={link.id === activeLink}
              key={link.id}
              onClick={() => setActiveLink(link.id)}
            >
              <Link to={link.path}>
                {link.name === "Food" ? (
                  <img src="/img/plans/nav/nutrition.svg" alt="Food Icon" />
                ) : link.name === "Workouts" ? (
                  <img src={link.icon} alt="Exercises Icon" />
                ) : (
                  <span className="material-symbols-outlined">{link.icon}</span>
                )}
                <p>{link.name}</p>
              </Link>
              {link.name === "Chat" &&
                !location.pathname.endsWith("/chat/home") &&
                unreadCountState > 0 && (
                  <ChatContNumber>{unreadCountState}</ChatContNumber>
                )}
            </SidebarLinksListItem>
          ))}
        </SidebarLinksList>
        <SidebarUserInfo />
        {/* Mobile View */}
        <MobileLogoutContainer
          onClick={() => {
            openModal();
            handleHideResponsiveSideber();
          }}
        >
          <span className="material-symbols-outlined">logout</span>
          <p>Logout</p>
        </MobileLogoutContainer>
        {/* Mobile View */}
      </SidebarContainer>
      <LogoutModal
        handleHideMobileLogout={closeModal}
        showLogoutModal={isModalOpen}
        clearAndUnpersistUser={clearAndUnpersistUser}
      />
    </>
  );
};

function buildLinks() {
  const links = [
    {
      id: 0,
      name: "Patients",
      icon: "groups",
      path: "/",
    },
    {
      id: 1,
      name: "Food",
      icon: "",
      path: "/food-logging",
    },
  ];

  if (FEATURE_FLAG_CHAT) {
    links.push({
      id: 2,
      name: "Chat",
      icon: "chat",
      path: "/chat",
    });
  }

  if (FEATURE_FLAG_ORGANIZATIONS_AND_ROLES) {
    links.unshift({
      id: 4,
      name: "Organizations",
      icon: "diversity_3",
      path: "/organizations",
    });
  }

  if (FEATURE_FLAG_CONTENT_MODULES) {
    links.push({
      id: 5,
      name: "Content",
      icon: "article",
      path: "/content",
    });
  }

  if (FEATURE_FLAG_HOME) {
    links.unshift({
      id: 6,
      name: "Home",
      icon: "home",
      path: "/home/immediate-attention-patients/1",
    });
  }

  if (FEATURE_FLAG_EXERCISES) {
    links.push({
      id: 7,
      name: "Workouts",
      icon: "/img/sidebar-exercises.png",
      path: "/workouts",
    });
  }

  return links;
}
