import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  regularRegularFont,
  regularSemiBoldFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import { transition } from "../../../../../../../styles/global-style";

export const WorkoutsContainerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  width: 95%;
  margin: 16px auto 0px;
  background-color: #fffcfdff;

  .workout-submit {
    margin-left: auto;
  }
`;

export const WorkoutsContainerHeader = styled.div<{ $showDropdown: boolean }>`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px;
  ${flexCenter}

  span {
    margin-left: auto;
    cursor: pointer;
    transform: ${({ $showDropdown }) =>
      $showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: ${transition};
  }
`;

export const WorkoutsContainerTitle = styled.p`
  margin: 0px 0px 0px auto;
  transform: translateX(50%);
  text-align: center;
  ${regularSemiBoldFont}
`;

export const WorkoutsContainerList = styled.ul<{ $showDropdown: boolean }>`
  ${flexCenterCenter}
  gap: 0px 16px;
  padding: 0px 8px;
  display: ${({ $showDropdown }) => ($showDropdown ? "flex" : "none")};
`;

export const WorkoutsContainerListItem = styled.li`
  display: grid;
  gap: 8px 0px;
  padding: 8px;
  border: 3px dashed ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;

  p {
    margin: 0px;
    text-align: center;
    ${regularRegularFont}
  }

  ${Button} {
    padding: 8px 16px;
    font-size: 14px;
  }
`;
