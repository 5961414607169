import {
  UseFieldArrayMove,
  UseFieldArrayRemove,
  useFormContext,
} from "react-hook-form";
import { useWorkouts } from "../../../../context/loadable-workouts-context";
import { useCallback, useMemo, useRef } from "react";
import { ExerciseDTO } from "../../../../../../../../../../models/exercises-dtos/exercise-dto";
import {
  WorkoutsExercisesList,
  WorkoutsExercisesListDetails,
} from "./styled-workouts-exercises-modal-body-list";
import { WorkoutExercisesInputs } from "../../types/workout-exercises-inputs";
import { calculateWorkoutDetails } from "../../../utils/calculate-workout-details";
import {
  ExerciseListItemImageContainer,
  ExerciseListItemInfoContainer,
  ExerciseListItemInfoItem,
  ExerciseListItemInfoSubContainer,
  ExerciseListItemListItem,
  ExerciseListItemNumberContainer,
  ExerciseListItemTextContainer,
} from "../../../../styles/styled-exercise-list-item";

type WorkoutsExercisesModalBodyListProps = {
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
};

export const WorkoutsExercisesModalBodyList = (
  props: WorkoutsExercisesModalBodyListProps
) => {
  const { remove, move } = props;

  const { watch, setValue, getValues } = useFormContext<
    WorkoutExercisesInputs
  >();
  const workoutExercisesWatcher = watch("workoutExercises");

  const dragItem = useRef<number>(0);
  const draggedOverItem = useRef<number>(0);

  const handleSort = useCallback(() => {
    move(dragItem.current, draggedOverItem.current);

    // After moving, update the `uiOrder` field for all items
    setValue(
      "workoutExercises",
      getValues("workoutExercises")?.map((item, index) => ({
        ...item,
        uiOrder: index + 1, // Assuming `uiOrder` starts at 1
      }))
    );
  }, [move, getValues, setValue]);

  const { exerciseDTOs } = useWorkouts();

  const {
    selectedExercises,
    totalWorkoutDuration,
    totalBurnedKcals,
  } = useMemo(() => {
    const selectedExercises: ExerciseDTO[] = [];

    if (workoutExercisesWatcher) {
      for (const watcherItem of workoutExercisesWatcher) {
        if (exerciseDTOs.some((item) => watcherItem.exerciseId === item.id)) {
          const exerciseIndex = exerciseDTOs.findIndex(
            (item) => watcherItem.exerciseId === item.id
          );

          selectedExercises.push(exerciseDTOs[exerciseIndex]);
        }
      }
    }

    const totalWorkoutDuration = calculateWorkoutDetails(selectedExercises)
      .totalWorkoutDuration;

    const totalBurnedKcals = calculateWorkoutDetails(selectedExercises)
      .totalBurnedKcals;

    return { selectedExercises, totalWorkoutDuration, totalBurnedKcals };
  }, [workoutExercisesWatcher, exerciseDTOs]);

  return (
    <>
      {selectedExercises.length > 0 && (
        <WorkoutsExercisesListDetails>
          <p>
            <span className="material-symbols-outlined">schedule</span>
            {totalWorkoutDuration} mins{" "}
          </p>
          <p>
            <span className="material-symbols-outlined">
              local_fire_department
            </span>
            {totalBurnedKcals} Kcal{" "}
          </p>
        </WorkoutsExercisesListDetails>
      )}
      <WorkoutsExercisesList>
        {selectedExercises.map((exerciseDTO, index) => (
          <ExerciseListItemListItem
            key={exerciseDTO.id}
            draggable
            onDragStart={() => (dragItem.current = index)}
            onDragEnter={() => (draggedOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <ExerciseListItemNumberContainer>
              <p>{index + 1}</p>
              <span className="material-symbols-outlined">menu</span>
            </ExerciseListItemNumberContainer>
            <ExerciseListItemImageContainer $width={"70px"} $height={"60px"}>
              {exerciseDTO.thumbnailUploadedFile ? (
                <img
                  src={exerciseDTO.thumbnailUploadedFile?.presignedUrl}
                  alt={exerciseDTO.name}
                />
              ) : (
                <span className="material-symbols-outlined">
                  directions_run
                </span>
              )}
            </ExerciseListItemImageContainer>
            <ExerciseListItemTextContainer>
              <p>{exerciseDTO.name}</p>
              <span>{exerciseDTO.description}</span>
            </ExerciseListItemTextContainer>
            <ExerciseListItemInfoContainer>
              <ExerciseListItemInfoSubContainer>
                <ExerciseListItemInfoItem>
                  <span className="material-symbols-outlined">schedule</span>
                  <p>{exerciseDTO.durationInMins} mins</p>
                </ExerciseListItemInfoItem>
                <ExerciseListItemInfoItem>
                  <span className="material-symbols-outlined">
                    local_fire_department
                  </span>
                  <p>{exerciseDTO.burnedKCals} kcal</p>
                </ExerciseListItemInfoItem>
              </ExerciseListItemInfoSubContainer>
              <span
                className="material-symbols-outlined workouts-exercises-list-item-remove-icon"
                onClick={() => remove(index)}
              >
                close
              </span>
            </ExerciseListItemInfoContainer>
          </ExerciseListItemListItem>
        ))}
      </WorkoutsExercisesList>
    </>
  );
};
