import styled from "styled-components/macro";
import {
  flexCenter,
  regularSemiBoldFont,
} from "../../../styles/classes/gloabl-classes";
import { transition } from "../../../styles/global-style";

export const WorkoutsNavbarNav = styled.nav`
  ${flexCenter}
  gap: 0px 16px;
  padding: 16px;
  position: relative;
  margin-bottom: 24px;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 24px;
    background: ${({ theme }) => theme.colors.barBackground};
    left: 0px;
    bottom: -14px;
  }
`;

export const WorkoutsNavbarLink = styled.div<{ $active: boolean }>`
  position: relative;
  transition: ${transition};

  a {
    ${regularSemiBoldFont}
    color: ${({ theme, $active }) =>
      $active ? theme.colors.primaryColorAlt : theme.colors.titleColor};
    text-decoration: none;
  }

  ::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: ${({ $active }) => ($active ? "100%" : "0px")};
    height: 4px;
    background: ${({ theme, $active }) =>
      $active ? theme.colors.primaryColorAlt : "transparent"};
  }
`;
