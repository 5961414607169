import styled, { css } from "styled-components/macro";
import {
  mediumRegularFont,
  flexCenter,
  flexCenterCenter,
  mediumFont,
  regularRegularFont,
  smallSemiBoldFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../styles/global-style";

export const ExerciseListItemListItemHoverCSS = css`
  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};

    span {
      color: ${({ theme }) => theme.colors.buttonColor} !important;
    }

    p {
      color: ${({ theme }) => theme.colors.buttonColor} !important;
    }
  }
`;

export const ExerciseListItemListItem = styled.li<{
  $disabled?: boolean;
  $height?: string;
  $hoverActive?: boolean;
}>`
  user-select: none;
  ${mediumRegularFont}
  ${flexCenter}
    cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  transition: ${transition};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  height: ${({ $height }) => ($height ? $height : "auto")};

  ${({ $hoverActive }) => $hoverActive && ExerciseListItemListItemHoverCSS};
`;

export const ExerciseListItemNumberContainer = styled.div`
  ${flexCenter}
  margin-right: 8px;

  p {
    margin: 0px;
    ${mediumFont}
  }

  span {
    font-size: 18px;
    margin-left: 4px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const ExerciseListItemImageContainer = styled.div<{
  $width: string;
  $height: string;
}>`
  max-width: ${({ $width }) => $width};
  max-height: ${({ $height }) => $height};
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  ${flexCenterCenter}
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

export const ExerciseListItemTextContainer = styled.div`
  p {
    margin: 0px;
    ${regularRegularFont}
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    ${mediumFont}
  }
`;

export const ExerciseListItemInfoContainer = styled.div`
  ${flexCenter}
  margin-left: auto;

  .exercise-list-item-icon {
    color: ${({ theme }) => theme.colors.primaryColor};
    margin-left: 24px;
  }
`;

export const ExerciseListItemInfoSubContainer = styled.div`
  display: grid;
`;

export const ExerciseListItemInfoItem = styled.div`
    ${flexCenter}
    gap: 0px 8px;
  
    p {
      margin: 0px;
      ${smallSemiBoldFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  
    span {
      font-size: 18px;
      color: ${({ theme }) => theme.colors.textColor};
    }
  `;
