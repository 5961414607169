import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumSemiBoldFont,
  regularRegularFont,
  regularSemiBoldFont,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const WorkoutsContainerDayWorkoutContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 400px;
  border-radius: 8px;

  .rest-day-text {
    ${regularSemiBoldFont}
    text-align: center;
  }
`;

export const WorkoutsContainerDayWorkoutHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px;
  ${flexCenter}
`;

export const WorkoutsContainerDayWorkoutHeaderTitle = styled.p`
  margin: 0px;
  margin-left: auto;
  text-align: center;
  ${regularRegularFont}
  transform: translateX(50%);
`;

export const WorkoutsContainerDayWorkoutHeaderActions = styled.div`
  margin-left: auto;
  ${flexCenter}
  gap: 0px 4px;

  span {
    cursor: pointer;
    font-size: 18px;

    :last-child {
      color: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const WorkoutsContainerDayWorkoutInformation = styled.div`
  padding: 0px 8px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const WorkoutsContainerDayWorkoutDetails = styled.div`
  ${flexCenterCenter}
  gap: 0px 16px;
  margin: 8px 0px;
`;

export const WorkoutsContainerDayWorkoutDetail = styled.div`
  ${flexCenter}
  gap: 0px 4px;

  p {
    margin: 0px;
  }

  span {
    font-size: 18px;
  }
`;
